
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <form
          class="needs-validation"
          @submit.prevent="userForm"
          @reset="onReset"
        >
          <div id="addproduct-accordion" class="custom-accordion">
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark"
                data-toggle="collapse"
                aria-expanded="true"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-1
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          01
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">ข้อมูลทั่วไป</h5>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#addproduct-accordion"
                id="accordion-1"
                visible
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="prefixId">คำนำหน้า:</label>
                        <multiselect
                          id="prefixId"
                          v-model="userform.prefixId"
                          :options="prefixOPtion"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="name">ชื่อ:</label>
                        <input
                          id="name"
                          v-model="userform.name"
                          type="text"
                          class="form-control"
                          value=""
                          :class="{
                            'is-invalid': submitform && $v.userform.name.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.userform.name.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.userform.name.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="familyName">นามสกุล:</label>
                        <input
                          id="familyName"
                          v-model="userform.familyName"
                          type="text"
                          class="form-control"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="gender">เพศ:</label>
                        <multiselect
                          id="gender"
                          v-model="userform.gender"
                          :options="options"
                          track-by="name"
                          label="name"
                          :show-labels="false"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <!-- <code>* </code > -->
                        <code>* </code
                        ><label for="citizenId">เลขบัตรประชาชน:</label>
                        <input
                          id="citizenId"
                          v-model="userform.citizenId"
                          maxLength="13"
                          type="text"
                          class="form-control input-placeholder"
                          :class="{
                            'is-invalid':
                              submitform && $v.userform.citizenId.$error,
                          }"
                          placeholder="รหัสบัตรประชาชน 13 หลัก"
                        />

                        <div
                          v-if="submitform && $v.userform.citizenId.$error"
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="
                              !$v.userform.citizenId.required ||
                              !$v.userform.citizenId.numeric ||
                              !$v.userform.citizenId.maxLength ||
                              !$v.userform.citizenId.minLength
                            "
                            >{{ message }}
                          </span>
                        </div>
                        <!-- <div v-else-if="submitform" class="invalid-tooltip">
                          <span v-if="this.checks === false">{{ err }} </span>
                          <span v-else-if="!this.checks === false" class="color"
                            >เลขบัตรประชาชนถูกต้อง</span
                          >
                        </div> -->
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="mb-3 position-relative">
                        <label for="birthDate">วันเกิด:</label>
                        <!-- disabled="disabled"  -->
                        <date-picker
                          v-model="userform.birthDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                        ></date-picker>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="mobilePhone">โทรศัพท์:</label>
                        <input
                          id="mobilePhone"
                          v-model="userform.mobilePhone"
                          type="tel"
                          class="form-control"
                          value=""
                          :maxLength="10"
                          :class="{
                            'is-invalid':
                              submitform && $v.userform.mobilePhone.$error,
                          }"
                          placeholder=""
                        />

                        <div
                          v-if="submitform && $v.userform.mobilePhone.$error"
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="
                              !$v.userform.mobilePhone.numeric ||
                              !$v.userform.mobilePhone.maxLength ||
                              !$v.userform.mobilePhone.minLength
                            "
                            >{{ message }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="buildingNumber">บ้านเลขที่:</label>
                        <input
                          id="buildingNumber"
                          v-model="userform.buildingNumber"
                          type="text"
                          class="form-control"
                          value=""
                        />
                        <span class="text-muted"
                          ><strong><code>999/999</code></strong></span
                        >
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="townshipNumber">หมู่:</label>
                        <input
                          :maxLength="10"
                          :minlength="1"
                          id="townshipNumber"
                          v-model="userform.townshipNumber"
                          type="text"
                          class="form-control"
                          value=""
                        />
                        <span class="text-muted"
                          ><strong><code>ตัวอย่าง 1,2,3</code></strong></span
                        >
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="townshipName">ชื่อหมู่บ้าน:</label>
                        <input
                          id="townshipName"
                          v-model="userform.townshipName"
                          type="text"
                          class="form-control"
                          value=""
                        />
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="subLane">ตรอก:</label>
                        <input
                          id="subLane"
                          v-model="userform.subLane"
                          type="text"
                          class="form-control"
                          value=""
                        />
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="lane">ซอย:</label>
                        <input
                          id="lane"
                          v-model="userform.lane"
                          type="text"
                          class="form-control"
                          value=""
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="streetName">ถนน:</label>
                        <input
                          id="streetName"
                          v-model="userform.streetName"
                          type="text"
                          class="form-control"
                          value=""
                        />
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="provinceId">จังหวัด:</label>
                        <multiselect
                          @input="getAmphures(provinceId)"
                          id="provinceId"
                          v-model="provinceId"
                          :options="provinces"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="amphurId">อำเภอ:</label>
                        <multiselect
                          @input="getDistricts(amphurId)"
                          id="amphurId"
                          v-model="amphurId"
                          :options="amphures"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                        ></multiselect>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="districtId">ตำบล:</label>
                        <multiselect
                          id="districtId"
                          v-model="districtId"
                          :options="districts"
                          :show-labels="false"
                          label="nameTh"
                          track-by="nameTh"
                        ></multiselect>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="postCode">รหัสไปรษณีย์:</label>
                        <input
                          id="postCode"
                          v-model="userform.postCode"
                          maxLength="5"
                          type="number"
                          class="form-control"
                          value=""
                          :class="{
                            'is-invalid':
                              submitform && $v.userform.postCode.$error,
                          }"
                        />

                        <div
                          v-if="submitform && $v.userform.postCode.$error"
                          class="invalid-tooltip"
                        >
                          <span
                            v-if="
                              !$v.userform.postCode.numeric ||
                              !$v.userform.postCode.minLength ||
                              !$v.userform.postCode.maxLength
                            "
                            >{{ message }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mb-3 position-relative">
                        <label for="fullAddress">ที่อยู่เต็ม:</label>
                        <!-- disabled="disabled"  -->
                        <textarea
                          disabled
                          v-model="fullAddress"
                          class="form-control"
                          name="textarea"
                          rows="5"
                        ></textarea>
                        <p></p>
                      </div>
                    </div>

                    <div class="col-sm-3">
                      <div class="mb-3 position-relative">
                        <label for="startWorkingDate">วันเริ่มงาน:</label>

                        <date-picker
                          v-model="userform.startWorkingDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <code>* </code><label for="roleId">สิทธิ์:</label>
                        <multiselect
                          id="roleId"
                          v-model="userform.roleId"
                          :options="optionsRole"
                          :show-labels="false"
                          :class="{
                            'is-invalid':
                              submitform && $v.userform.roleId.$error,
                          }"
                          label="nameTh"
                          track-by="nameTh"
                        ></multiselect>

                        <div
                          v-if="submitform && $v.userform.roleId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.userform.roleId.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3 position-relative">
                        <code>* </code
                        ><label for="branchId">สาขาที่สร้าง:</label>
                        <multiselect
                          id="branchId"
                          v-model="userform.branchId"
                          :options="localDataBranchUser"
                          :show-labels="false"
                          :class="{
                            'is-invalid':
                              submitform && $v.userform.branchId.$error,
                          }"
                          label="nameTh"
                          track-by="nameTh"
                        ></multiselect>

                        <div
                          v-if="submitform && $v.userform.branchId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.userform.branchId.required">{{
                            message
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-4">
                      <p class="card-title-desc">ข้อมูลการเข้าสู่ระบบ</p>
                      <hr />

                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="email">อีเมล:</label>
                          <input
                            id="email"
                            v-model="userform.email"
                            type="email"
                            class="form-control input-placeholder"
                            value=""
                            :class="{
                              'is-invalid':
                                submitform && $v.userform.email.$error,
                            }"
                            placeholder="example@gmail.com"
                          />
                          <div
                            v-if="submitform && $v.userform.email.$error"
                            class="invalid-tooltip"
                          >
                            <span v-if="!$v.userform.email.required">{{
                              message
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3 position-relative">
                          <code>* </code><label for="password">รหัสผ่าน:</label>
                          <div class="inner-addon right-addon input-group">
                            <input
                              v-if="showPassword"
                              type="text"
                              v-model="userform.password"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitform && $v.userform.password.$error,
                              }"
                            />
                            <input
                              v-else
                              type="password"
                              v-model="userform.password"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitform && $v.userform.password.$error,
                              }"
                            />
                            <a
                              @click="toggleShow"
                              class="btn btn-outline-primary"
                            >
                              <i
                                class="fas glyphicon"
                                :class="{
                                  'uil-eye ': showPassword,
                                  'uil-eye-slash': !showPassword,
                                }"
                              ></i>
                            </a>
                          </div>

                          <span class="text-muted"
                            ><strong
                              ><code>รหัสผ่านอย่างน้อย 8 ตัว</code></strong
                            ></span
                          >
                          <div
                            v-if="submitform && $v.userform.password.$error"
                            class="invalid-tooltip"
                          >
                            <span
                              v-if="
                                !$v.userform.password.required ||
                                !$v.userform.password.minLength
                              "
                              >{{ message }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <!-- <div class="inner-addon right-addon">
                        <i class="uil-eye-slash glyphicon"></i>
                        <input type="text" class="form-control" />
                      </div> -->
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>

            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark collapsed"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="addproduct-img-collapse"
                v-b-toggle.accordion-2
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="
                            avatar-title
                            rounded-circle
                            bg-soft-primary
                            text-primary
                          "
                        >
                          02
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">การเข้าใช้งาน</h5>
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                id="accordion-2"
                accordion="my-accordion"
                data-parent="#addproduct-accordion"
              >
                <div class="p-4 border-top">
                  <p class="card-title-desc">สิทธิ์การเข้าใช้งานสาขา</p>
                  <div class="row g-5">
                    <div class="col-lg-12 col-12">
                      <div
                        class="row align-items-center"
                        style="margin-left: 3rem"
                      >
                        <div
                          class="col-md-4 col-lg-3 m-1"
                          v-for="(branchItem, index) in localDataBranchUser"
                          :key="index"
                        >
                          <b-form-checkbox
                            v-model="accessBranchId"
                            :value="branchItem.branchId"
                            class="mb-2 me-2"
                            plain
                            checked
                            :class="{
                              'is-invalid': submitform,
                            }"
                          >
                            {{ branchItem.nameTh }}</b-form-checkbox
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col">
                      <p class="card-title-desc">สิทธิ์การเข้าใช้งานระบบ</p>
                      <hr />

                      <div class="row g-5">
                        <div class="col-12">
                          <div
                            class="col-md-4 col-md-offset-4"
                            style="margin-left: 3rem"
                          >
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="selectAll"
                              @click="selectAlls"
                            />
                            เลือกทั้งหมด
                          </div>
                          <div
                            class="col-md-4 col-md-offset-4"
                            style="margin-left: 3rem"
                            v-for="(programItem, index) in rowProgram"
                            :key="index"
                          >
                            <div class="row">
                              <div v-if="programItem.lvl == 1">
                                <b-form-checkbox
                                  v-model="accessProgramId"
                                  class="mb-3"
                                  :value="programItem.programId"
                                  plain
                                  checked
                                  :class="{
                                    'is-invalid': submitform,
                                  }"
                                >
                                  <b>{{ programItem.nameTh }}</b>
                                </b-form-checkbox>
                              </div>
                              <div v-if="programItem.lvl == 2">
                                <b-form-checkbox
                                  v-model="accessProgramId"
                                  class="mb-3 ml-2"
                                  :value="programItem.programId"
                                  plain
                                  checked
                                  :class="{
                                    'is-invalid': submitform,
                                  }"
                                >
                                  {{ programItem.nameTh }}
                                </b-form-checkbox>
                              </div>
                              <div v-if="programItem.lvl == 3">
                                <b-form-checkbox
                                  v-model="accessProgramId"
                                  class="mb-3 ml-3"
                                  :value="programItem.programId"
                                  plain
                                  checked
                                  :class="{
                                    'is-invalid': submitform,
                                  }"
                                >
                                  {{ programItem.nameTh }}
                                </b-form-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col text-end ms-1">
              <button type="reset" class="btn btn-warning" @click="onReset()">
                <i class="uil-refresh me-1"></i> ล้างข้อมูล
              </button>
              <button type="submit" class="btn btn-success ms-1">
                <i class="uil uil-file-alt me-1"></i> บันทึก
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </Layout>
</template>

<script>
// import vue2Dropzone from "vue2-dropzone";
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  required,
  numeric,
  maxLength,
  minLength,
  // maxValue,
} from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
// import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

/**
 * Add-product component
 */
export default {
  page: {
    title: appConfig.users,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // vueDropzone: vue2Dropzone,
    Multiselect,
    Layout,
    PageHeader,
    DatePicker,
  },

  data() {
    return {
      err: "รหัสบัตรประชาชนไม่ถูกต้อง",
      message: "โปรดตรวจสอบข้อมูล",
      title: appConfig.users,
      items: [
        {
          text: "ข้อมูลหลัก",
          active: true,
        },
        {
          text: "แผนกบุคคล",
          active: true,
        },

        {
          text: appConfig.users,
          href: "/main-user",
        },
        {
          text: "เพิ่มพนักงาน",
          active: true,
        },
      ],
      rowProgramItem: [],
      rowProgram: [],
      roleName: "",
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      total: "",
      per_page: "",
      total_pages: "",
      rowpage: "",
      totalRecord: "",
      rowUser: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      fields: [
        {
          key: "selected",
        },
        {
          key: "email",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "familyName",
          sortable: true,
        },
        {
          key: "roleName",
          sortable: true,
        },

        "action",
      ],
      showPassword: false,
      checks: "",
      value1: null,
      districtId: { nameTh: "" },
      amphurId: { nameTh: "" },
      provinceId: { nameTh: "" },
      branchId: [],
      optionsRole: [],
      provinces: [],
      amphures: [],
      districts: [],
      prefixOPtion: [],
      rowBranch: [],
      localDataBranchUser: [],
      localDataBranchIdUser: [],
      options: [
        {
          name: "ชาย",
          id: "M",
        },
        {
          name: "หญิง",
          id: "F",
        },
      ],
      check: true,
      prefixId: "",
      name: "",
      familyName: "",
      gender: "",
      citizenId: "",
      birthDate: "",
      mobilePhone: "",
      email: "",
      buildingNumber: "",
      townshipNumber: "",
      townshipName: "",
      subLane: "",
      lane: "",
      streetName: "",
      selectAll: false,
      postCode: "",
      startWorkingDate: "",
      roleId: "",
      username: "",
      password: "",

      rowBranchItem: [],
      program: [],
      formatted: "",
      selected2: "",
      accessBranchId: [],
      accessProgramId: [],
      userform: {
        prefixId: "",
        name: "",
        familyName: "",
        gender: "",
        citizenId: "",
        birthDate: "",
        mobilePhone: "",
        email: "",
        buildingNumber: "",
        townshipNumber: "",
        townshipName: "",
        subLane: "",
        lane: "",
        streetName: "",

        postCode: "",
        startWorkingDate: "",
        roleId: "",
        username: "",
        password: "",
        branchId: "",
        accessBranchId: "",
        accessProgramId: "",
      },
      submitform: false,
    };
  },
  validations: {
    userform: {
      branchId: { required },
      name: {
        required,
      },
      citizenId: {
        required,
        numeric,
        minLength: minLength(13),
        maxLength: maxLength(13),
      },
      mobilePhone: {
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      email: {
        required,
      },

      postCode: {
        numeric,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
      roleId: {
        required,
      },

      password: {
        required,
        minLength: minLength(8),
      },
    },
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    nameState() {
      return this.userform.citizenId.length == 13 ? true : false;
    },
    fullAddress: function () {
      //

      return `${
        this.userform.buildingNumber != ""
          ? "" + this.userform.buildingNumber
          : this.userform.buildingNumber
      } ${
        this.userform.townshipNumber != ""
          ? "หมู่" + " " + this.userform.townshipNumber
          : this.userform.townshipNumber
      } ${
        this.userform.townshipName != ""
          ? "" + this.userform.townshipName
          : this.userform.townshipName
      } ${
        this.userform.subLane != ""
          ? "ตรอก" + " " + this.userform.subLane
          : this.userform.subLane
      } ${
        this.userform.lane != ""
          ? "ซอย" + " " + this.userform.lane
          : this.userform.lane
      } ${
        this.userform.streetName != ""
          ? "ถนน" + " " + this.userform.streetName
          : this.userform.streetName
      } ${
        this.districtId != "" &&
        this.districtId != undefined &&
        this.districtId != null &&
        this.districtId.nameTh != "" &&
        this.districtId.nameTh != undefined &&
        this.districtId.nameTh != null
          ? this.districtId.nameTh
          : ""
      } ${
        this.amphurId != "" &&
        this.amphurId != undefined &&
        this.amphurId != null &&
        this.amphurId.nameTh != "" &&
        this.amphurId.nameTh != undefined &&
        this.amphurId.nameTh != null
          ? this.amphurId.nameTh
          : ""
      } ${
        this.provinceId != "" &&
        this.provinceId != null &&
        this.provinceId.nameTh != null &&
        this.provinceId.nameTh != ""
          ? this.provinceId.nameTh
          : ""
      }  ${
        this.userform.postCode != ""
          ? "รหัสไปรษณีย์" + " " + this.userform.postCode
          : this.userform.postCode
      }
      `;
    },
  },
  mounted() {},
  created() {
    this.getProgram();
    // this.getBranch();
    this.getLocalData();
    this.getRole();
    this.getPrefixes();
    this.getProvinces();
    this.getUser();
  },
  watch: {
    accessProgramId(newValue) {
      if (newValue.length !== this.rowProgram.length) {
        this.selectAll = false;
      } else if (newValue.length === this.rowProgram.length) {
        this.selectAll = true;
      }
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    selectAlls() {
      this.accessProgramId = [];
      if (!this.selectAll) {
        for (let i in this.rowProgram) {
          this.accessProgramId.push(this.rowProgram[i].programId);
        }
      }
    },
    checkCitizenId: function () {
      if (this.userform.citizenId.substring(0, 1) === 0) return false; //substring ระหว่างตำแหน่ง 0, 1
      if (this.userform.citizenId.length != 13) return false;
      var sum = 0;
      var item = 0;
      for (item = 0, sum = 0; item < 12; item++) {
        sum += parseInt(this.userform.citizenId.charAt(item)) * (13 - item);
      }
      let mod = sum % 11;
      let check = (11 - mod) % 10;
      if (check == parseInt(this.userform.citizenId.charAt(12))) {
        return true;
      }
      return false;
    },

    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.localDataProgram = user.userAccessProgram;
      this.branchItem = user.branchId;

      const program = [];
      this.localDataProgram.forEach((item) => {
        program.push(item);
      });
      this.program = program;
      const localDataBranch = [];
      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranch.push(item), localDataBranchId.push(item.branchId);
      });
      this.localDataBranchUser = localDataBranch;
      this.localDataBranchIdUser = localDataBranchId;

      // console.log("โปรแกรม",  this.localDataBranchUser );
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowUser = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowUser) {
      this.selected = rowUser;
      // this.getData();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    // @click="postUser()"
    userForm() {
      this.submitform = true;
      // var result = this.checkCitizenId();
      // this.checks = result;
      this.$v.$touch();
      if (this.$v.userform.$invalid != true) {
        //  && this.checks != false
        this.postUser();
      }
    },

    getRole: function () {
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/roles", {})
        .then((response) => {
          this.optionsRole = response.data.data;
          // console.log(this.optionsRole);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getUser: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/user", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranchIdUser,
          },
        })
        .then((response) => {
          this.rowUser = response.data.data;

          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getPrefixes: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/person/prefixes", {})
        .then((response) => {
          this.prefixOPtion = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    getProvinces: function () {
      //  if(this.provinceId != null && this.provinceId != "" && this.provinceId != undefined) {
      this.loading = true; //skeleton true
      useNetw
        .get("api/master/address/provinces", {})
        .then((response) => {
          this.provinces = response.data.data;
          // console.log( this.provinces);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
      //  }
    },
    getAmphures: function (isReset) {
      if (
        this.provinceId != "" &&
        this.provinceId != undefined &&
        this.provinceId != null
      ) {
        this.loading = true; //skeleton true
        useNetw
          .get("api/master/address/amphures", {
            params: {
              provinceId: this.provinceId.provinceId,
            },
          })
          .then((response) => {
            this.amphures = response.data.data;

            if (isReset) {
              this.amphurId = "";
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            // this.overlayFlag = false;
            this.loading = false; //skeleton false
          });
      }
    },
    getDistricts: function (isReset) {
      if (
        this.provinceId != "" &&
        this.provinceId != undefined &&
        this.provinceId != null &&
        this.amphurId != null &&
        this.amphurId != ""
      ) {
        useNetw
          .get("api/master/address/districts", {
            params: {
              amphurId: this.amphurId.amphureId,
            },
          })
          .then((response) => {
            this.districts = response.data.data;
            if (isReset) {
              this.districtId = "";
            }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message),
              appConfig.swal.type.error
            );
          })
          .then(() => {});
      }
    },
    getBranch: function () {
      useNetw
        .get("api/branch", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.rowBranch = response.data.data;
          // console.log("สาขา", response);
          const arr = [];
          this.rowBranch.forEach((item) => {
            arr.push(item);
          });
          this.rowBranchItem = arr;
          // console.log(arr);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    getProgram: function () {
      useNetw
        .get("api/master/programs", {})
        .then((response) => {
          this.rowProgram = response.data.data;

          const arr = [];
          this.rowProgram.forEach((item) => {
            arr.push(item.lvl);
          });
          this.rowProgramItem = arr;
          // console.log("เมนู", this.rowProgram);
          // console.log(this.rowProgramItem);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
        });
    },
    postUser: function () {
      this.overlayFlag = true;

      useNetw
        .post("/api/user/store", {
          prefixId: this.userform.prefixId.prefixId,
          name: this.userform.name,
          familyName: this.userform.familyName,
          gender: this.userform.gender.id,
          citizenId: this.userform.citizenId,
          birthDate: this.userform.birthDate,
          mobilePhone: this.userform.mobilePhone,
          email: this.userform.email,
          buildingNumber: this.userform.buildingNumber,
          townshipNumber: this.userform.townshipNumber,
          townshipName: this.userform.townshipName,
          subLane: this.userform.subLane,
          lane: this.userform.lane,
          streetName: this.userform.streetName,
          districtId: this.districtId.districtId,
          amphurId: JSON.stringify(this.amphurId.amphureId),
          provinceId: JSON.stringify(this.provinceId.provinceId),
          postCode: this.userform.postCode,

          fullAddress: this.fullAddress,
          startWorkingDate: this.userform.startWorkingDate,
          roleId: JSON.stringify(this.userform.roleId.roleId),
          password: this.userform.password,
          branchId: this.userform.branchId.branchId,
          accessBranchId: this.accessBranchId,
          accessProgramId: this.accessProgramId,
        })
        .then((response) => {
          this.getUser();
          this.checks == true;
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "main-user" });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          this.getUser();
        });
    },

    ChangePage(page) {
      this.currentPage = page;
      this.getUser();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }
      this.paginatedData = this.rowUser.slice(this.startIndex, this.endIndex);
    },
    PageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getUser();
      this.startIndex = (this.currentPage - 1) * this.perPage + 1;
      this.endIndex = (this.currentPage - 1) * this.perPage + this.perPage;
      if (this.endIndex > this.totalRecord) {
        this.endIndex = this.totalRecord;
      } else {
        return this.endIndex;
      }
      this.paginatedData = this.rowUser.slice(this.startIndex, this.endIndex);
    },
    onReset() {
      // event.preventDefault();
      // Reset our form values
      this.userform.prefixId = [];
      this.userform.name = "";
      this.userform.familyName = "";
      this.userform.gender = [];
      this.userform.citizenId = "";
      this.userform.birthDate = "";
      this.userform.mobilePhone = "";
      this.userform.buildingNumber = "";
      this.userform.townshipNumber = "";
      this.userform.subLane = "";
      this.userform.lane = "";
      this.userform.streetName = "";
      this.provinceId = [];
      this.amphurId = [];
      this.districtId = [];
      this.userform.postCode = "";
      this.userform.fullAddress = "";
      this.userform.startWorkingDate = "";
      this.userform.roleId = [];
      this.userform.branchId = [];
      this.userform.email = "";
      this.userform.password = "";
      this.accessBranchId = [];
      this.accessProgramId = [];
    },
  },
  middleware: "authentication",
};
</script>
<style scoped>
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.input-placeholder {
  font-size: 13px;
}
.color {
  color: rgb(0, 109, 42);
  background-color: rgb(255, 255, 255);
  border-radius: 0.25rem;
  padding-top: 0.35rem;
  padding-bottom: 0.4rem;
  background-position: center;
}
.eyeButton {
  position: absolute;
  right: 5px;
  bottom: 4px;
  top: 23px;
}
.inner-addon {
  position: relative;
}

/* style icon */
/* .inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
} */

/* align icon */
.left-addon .glyphicon {
  left: 0px;
}
.right-addon .glyphicon {
  right: 4px;
}

/* add padding  */
.left-addon input {
  padding-left: 30px;
}
.right-addon input {
  padding-right: 10px;
}
</style>

